import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { navigationData } from "../data/navigationData";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "../icons/ChevronDownIcon";
import AppNavigator from "./AppNavigator";

const Sidebar = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownClick = (title) => {
    if (openDropdown === title) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(title);
    }
  };

  const handleLinkClick = () => {
    setOpenDropdown(null);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleLogoutClick = () => {
    navigate("/logout");
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative">
      <button className="text-black text-2xl p-2" onClick={handleToggleSidebar}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>

      <div
        ref={sidebarRef}
        className={`xl:hidden fixed top-[61px] right-0 w-60 bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200 border border-r-0 border-primary-400 dark:border-primary-200 shadow-xl rounded-tl-lg rounded-bl-lg transform transition-all duration-300 z-50 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {isOpen && (
          <div className="p-4 space-y-4 pb-8 ">
            {navigationData.map((item) => (
              <SidebarItem
                key={item.title}
                item={item}
                openDropdown={openDropdown}
                handleDropdownClick={handleDropdownClick}
                handleLinkClick={handleLinkClick}
              />
            ))}
            {!token && (
              <Link
                to="/login"
                className="block py-1 px-4 bg-gradient-to-r from-green-500 to-teal-500 text-white text-center rounded-lg hover:bg-[#653dff] transition-colors duration-200"
                onClick={() => setIsOpen(false)}
              >
                Login / Register
              </Link>
            )}
            {token && (
              <button
                className="block w-full py-1 text-md bg-red-500 rounded-lg text-gray-700 hover:bg-gray-200"
                onClick={() => {
                  handleLogoutClick();
                  setIsOpen(false);
                }}
              >
                Logout
              </button>
            )}
            <Link
              to="/evaluate"
              className="block py-1 px-4 text-center bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg hover:bg-[#653dff] transition-colors duration-200"
              onClick={() => setIsOpen(false)}
            >
              Evaluate at no-cost
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const SidebarItem = ({
  item,
  openDropdown,
  handleDropdownClick,
  handleLinkClick,
}) => {
  const isOpen = openDropdown === item.title;

  return (
    <div className="relative">
      <div
        className="block px-2 "
        onClick={() => handleDropdownClick(item.title)}
      >
        <Link
          key={item.title}
          to={!item.dropdownIcon && item.path}
          className="block text-accent-700 dark:text-accent-300 dark:hover:text-text-300 hover:text-green-500"
          onClick={!item.dropdownIcon && handleLinkClick}
        >
          {item.title}
          {item.dropdownIcon && (
            <ChevronDownIcon className="w-4 h-4 ml-2  text-sm inline-block" />
          )}
        </Link>
      </div>
      {isOpen && item.submenu && (
        <DropdownMenu
          submenu={item.submenu}
          handleLinkClick={handleLinkClick}
        />
      )}
    </div>
  );
};

const DropdownMenu = ({ submenu, handleLinkClick }) => {
  return (
    <div>
      {submenu.map((subItem) => (
        <DropdownItem
          key={subItem.title}
          item={subItem}
          handleLinkClick={handleLinkClick}
        />
      ))}
    </div>
  );
};

const DropdownItem = ({ item, handleLinkClick }) => {
  const [isNestedOpen, setIsNestedOpen] = useState(false);

  const handleNestedDropdownClick = () => {
    setIsNestedOpen(!isNestedOpen);
  };

  return (
    <div className="relative">
      <div className="block px-2" onClick={handleNestedDropdownClick}>
        {item.isExternal ? (
          <AppNavigator navigateTo={item.path}>
            <span className=" w-full h-full flex items-center justify-start px-4 py-2 text-sm text-text-900 hover:bg-gray-100 dark:text-text-200 dark:hover:bg-gray-700">
              {item.title}
            </span>
          </AppNavigator>
        ) : (
          <Link
            key={item.title}
            to={!item.dropdownIcon && item.path}
            className="block px-4 py-1 text-text-700 dark:text-text-300  hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-green-500 "
            onClick={!item.dropdownIcon && handleLinkClick}
          >
            {item.title}
            {item.dropdownIcon && (
              <ChevronDownIcon className="w-4 h-4 ml-2 text-sm inline-block" />
            )}
          </Link>
        )}
      </div>
      {isNestedOpen && item.submenu && (
        <div className="pl-6">
          {item.submenu.map((nestedItem) => (
            <Link
              key={nestedItem.title}
              to={nestedItem.path}
              className="block px-4 py-2 text-secondary-500 hover:bg-gray-100 dark:text-secondary-400 dark:hover:bg-gray-700 hover:text-green-500"
              onClick={handleLinkClick}
            >
              {nestedItem.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
