import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { logout } from "../redux/authSlice"; // Import the logout action

const PrivateRoute = ({ children }) => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(null); // State to track token validity

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setIsValid(false);
        return;
      }

      try {
        // Verify the token with the backend
        const response = await axios.post(`${apiURL}/api/auth/verify-token`, {
          token,
        });
        if (response.data.isValid) {
          setIsValid(true); // Token is valid
        } else {
          setIsValid(false); // Token is invalid
        }
      } catch (error) {
        setIsValid(false); // Handle error, e.g., token expired or invalid
        dispatch(logout()); // Log out the user if the token is invalid
      }
    };

    verifyToken();
  }, [token, dispatch, apiURL]);

  // If token verification is in progress, you can show a loading indicator or simply return null
  if (isValid === null) {
    return null; // Or a loading spinner
  }

  // If the token is valid, render the protected component, otherwise redirect to login
  return isValid ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
