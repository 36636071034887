import React, { lazy, Suspense } from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Layout from "./components/Layout";
import ScrollIndicator from "./components/ScrollIndicator";
import ScrollToTop from "./components/ScrollToTop";
// import Home from "./pages/Home";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import VerifyEmail from "./pages/auth/VerifyEmail";
import Logout from "./pages/auth/Logout";
import ResetPassword from "./pages/auth/ResetPassword";
import SlideCircuit from "./pages/products/slideCircuitPro/SlideCircuit";
import SlideWave from "./pages/products/slideCircuitPro/SlideWave";
import SlideSystem from "./pages/products/slideCircuitPro/SlideSystem";
import SlideRTL from "./pages/products/slideCircuitPro/SlideRTL";
import SlideUPF from "./pages/products/slideCircuitPro/SlideUPF";
import EDAcloud from "./pages/products/EDAcloud";
import WhitePapers from "./pages/resources/WhitePapers";
import Videos from "./pages/resources/Videos";
import Webinars from "./pages/resources/Webinars";
import About from "./pages/company/About";
import Careers from "./pages/company/Careers";
import Partners from "./pages/company/Partners";
import Media from "./pages/company/Media";
import Newsletter from "./pages/company/Newsletter";
import FAQ from "./pages/company/FAQ";
import Confirmation from "./pages/license/Confirmation";
import Checkout from "./pages/license/Checkout";
import Receipt from "./pages/license/Receipt";
import PaymentDashboard from "./pages/license/PaymentDashboard";
import Evaluation from "./pages/license/Evaluation";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import Settings from "./pages/Settings";
import GetADemo from "./pages/GetADemo";
import RequestEvaluation from "./pages/RequestEvaluation";
import TermsAndConditions from "./pages/TermsAndConditions";
import LegalNotice from "./pages/LegalNotice";
import SoftwareLicenseAgreement from "./pages/SoftwareLicenseAgreement";
import Products from "./pages/Products";
import Error404 from "./components/Error404";
import CancellationAndRefundPolicy from "./pages/CancellationAndRefundPolicy";
import ShippingAndDeliveryCondition from "./pages/ShippingAndDeliveryCondition";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import DownloadScripts from "./pages/downloads/Downloads";
import SlideCircuiPro from "./pages/products/SlideCircuiPro";
import Downloads from "./pages/downloads/Downloads";
import UserDashboard from "./pages/Dashboard";
import BasicInfo from "./components/user-dashboard/BasicInfo";
import AccountDetails from "./components/user-dashboard/AccountDetailsContent";
import OrdersContent from "./components/user-dashboard/Orders";
import AddressesContent from "./components/user-dashboard/AddressContent";
import DownloadsContent from "./components/user-dashboard/DownloadsContent";
import SupportTicketsContent from "./components/user-dashboard/SupportTicketsContent";
import SubmitTicketContent from "./components/user-dashboard/SubmitTicketContent";
import UnderDevelopment from "./components/UnderDevelopment";
import { LoaderProvider } from "./components/LoaderContext";
import Loader from "./components/Loader";

const Home = lazy(() => import("./pages/Home"));
function App() {
  return (
    <Provider store={store}>
      <LoaderProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollIndicator />
            <ScrollToTop />
            <Analytics />
            <SpeedInsights />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="verify-email" element={<VerifyEmail />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="logout" element={<Logout />} />
                <Route path="legal-notice" element={<LegalNotice />} />
                <Route path="downloads" element={<DownloadScripts />} />
                <Route
                  path="cancellation-refund"
                  element={<CancellationAndRefundPolicy />}
                />
                <Route
                  path="shipping-delivery"
                  element={<ShippingAndDeliveryCondition />}
                />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route
                  path="software-license-agreement"
                  element={<SoftwareLicenseAgreement />}
                />
                {/* Product Routes */}
                <Route path="products" element={<Products />} />
                <Route
                  path="products/slidecircuitpro"
                  element={<SlideCircuiPro />}
                />
                <Route path="products">
                  <Route path="slidecircuitpro">
                    <Route path="slidecircuit" element={<SlideCircuit />} />
                    <Route path="slidewave" element={<SlideWave />} />
                    <Route path="slidesystem" element={<SlideSystem />} />
                    <Route path="slidertl" element={<SlideRTL />} />
                    <Route path="slideupf" element={<SlideUPF />} />
                  </Route>
                  <Route path="eda-cloud" element={<EDAcloud />} />
                </Route>
                {/* Resources Routes */}
                <Route path="resources" element={<Products />} />
                <Route path="resources">
                  <Route path="white-papers" element={<WhitePapers />} />
                  <Route path="videos" element={<Videos />} />
                  <Route path="webinars" element={<Webinars />} />
                  <Route
                    path="downloads"
                    element={
                      <PrivateRoute>
                        <Downloads />
                      </PrivateRoute>
                    }
                  />
                </Route>
                {/* Contact Routes */}
                <Route path="contact-us" element={<Contact />} />
                <Route path="get-a-demo" element={<GetADemo />} />
                <Route
                  path="request-evaluation"
                  element={<RequestEvaluation />}
                />
                <Route path="support" element={<Contact />} />
                {/* Company Routes */}
                <Route path="company" element={<About />} />
                <Route path="company">
                  <Route path="about-us" element={<About />} />
                  <Route path="careers" element={<Careers />} />
                  <Route path="partners" element={<Partners />} />
                  <Route path="media" element={<Media />} />
                  <Route path="newsletter" element={<Newsletter />} />
                  <Route path="faqs" element={<FAQ />} />
                </Route>
                {/* License Routes */}
                <Route path="license" element={<UnderDevelopment />} />
                <Route path="evaluate" element={<Evaluation />} />
                {/* Private Routes */}
                <Route
                  path="confirmation"
                  element={
                    <PrivateRoute>
                      <Confirmation />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="checkout"
                  element={
                    <PrivateRoute>
                      <Checkout />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="dashboard/:method"
                  element={
                    <PrivateRoute>
                      <PaymentDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="receipt"
                  element={
                    <PrivateRoute>
                      <Receipt />
                    </PrivateRoute>
                  }
                />
                {/* Dashboard */}

                {/* Private Dashboard Route */}
                <Route
                  path="account"
                  element={
                    <PrivateRoute>
                      <UserDashboard />
                    </PrivateRoute>
                  }
                >
                  {/* Nested routes within "account" */}
                  <Route index element={<BasicInfo />} />
                  <Route path="basic-info" element={<BasicInfo />} />
                  <Route path="account-details" element={<AccountDetails />} />
                  <Route path="orders" element={<OrdersContent />} />
                  <Route path="addresses" element={<AddressesContent />} />
                  <Route path="downloads" element={<DownloadsContent />} />
                  <Route
                    path="support-tickets"
                    element={<SupportTicketsContent />}
                  />
                  <Route
                    path="submit-new-ticket"
                    element={<SubmitTicketContent />}
                  />
                </Route>

                <Route
                  path="settings"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<Error404 />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Suspense>
      </LoaderProvider>
    </Provider>
  );
}

export default App;
