import React, { createContext, useState, useContext } from "react";
import Loader from "./Loader";

// Create the context
const LoaderContext = createContext();

// Provider component to wrap the app
export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && <Loader />}
      {children}
    </LoaderContext.Provider>
  );
};

// Hook to use the loader
export const useLoader = () => useContext(LoaderContext);
