import React, { useEffect, useState } from "react";
import Loader from "./Loader";

const AppNavigator = ({ navigateTo, children, className }) => {
  const [loading, setLoading] = useState(false);

  const handleNavigation = (e) => {
    e.preventDefault();
    setLoading(true);
    window.location.href = navigateTo;
  };

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      <a
        href={navigateTo}
        onClick={handleNavigation}
        className={`w-full h-full flex items-center justify-center ${className} cursor-pointer hover:text-blue-600`}
        style={{ textDecoration: "none" }}
      >
        {children}
      </a>
    </>
  );
};

export default AppNavigator;
