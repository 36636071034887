import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  IoLogOutOutline,
  IoPersonCircleOutline,
  IoSearch,
  IoSettingsOutline,
} from "react-icons/io5";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "../icons/ChevronDownIcon";
import { ChevronRightIcon } from "../icons/ChevronRightIcon";
import { navigationData } from "../data/navigationData";
import Sidebar from "./Sidebar";
import { MdDashboard } from "react-icons/md";
import AppNavigator from "./AppNavigator";

const Navbar = () => {
  const { token, user } = useSelector((state) => state.auth);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const profileRef = useRef();

  const handleMouseEnter = (title) => {
    setOpenDropdown(title);
  };

  const handleMouseLeave = () => {
    setOpenDropdown(null);
  };

  const handleLinkClick = () => {
    setOpenDropdown(null);
  };

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setIsProfileOpen(false);
    }
  };

  useEffect(() => {
    if (isProfileOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isProfileOpen]);

  return (
    <>
      <header className="fixed z-20 w-full  bg-background-200 dark:bg-background-700 shadow-lg border-b border-primary-400 dark:border-primary-200">
        <div className="container mx-auto flex items-center justify-between px-4 py-2">
          <div className="flex items-center space-x-2">
            <Link to="/" className="flex-shrink-0 gap-2">
              <img
                src="/logo.png"
                width={200}
                alt="logo"
                className="aspect-auto"
              />
            </Link>
          </div>

          <nav className="hidden z-50 xl:flex flex-grow font-semibold leading-6 justify-center items-center space-x-6">
            <div className="flex items-center space-x-0">
              {navigationData.map((item) => (
                <NavItem
                  key={item.title}
                  item={item}
                  openDropdown={openDropdown}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleLinkClick={handleLinkClick}
                />
              ))}
            </div>
          </nav>

          <div className="flex items-center justify-center space-x-4">
            <button className="hidden text-gray-600 hover:text-gray-900 dark:text-text-300 dark:hover:text-text-200">
              <IoSearch className="h-8 w-8" />
            </button>
            {token && (
              <div ref={profileRef} className="relative">
                <button
                  onClick={() => setIsProfileOpen(!isProfileOpen)}
                  className="text-rose-500 hover:text-green-500 pt-2"
                >
                  <IoPersonCircleOutline className="h-8 w-8" />
                </button>

                {isProfileOpen && (
                  <div className="absolute right-0 z-50 mt-2 w-60 text-wrap  bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200 border border-primary-400 dark:border-primary-200 shadow-xl rounded-lg divide-y divide-primary-200 dark:divide-primary-400">
                    <div className="px-4 py-2 text-indigo-500">
                      <div className="flex items-center justify-center gap-4">
                        <IoPersonCircleOutline className="h-8 w-8 text-text-700 dark:text-text-300" />
                        <span>
                          <p className="font-bold">
                            {user.firstName + " " + user.lastName}
                          </p>
                          <p className="text-sm text-green-500">{user.email}</p>
                        </span>
                      </div>
                    </div>
                    <div className="  divide-y divide-primary-200 dark:divide-primary-400">
                      <Link
                        to="/account"
                        onClick={() => setIsProfileOpen(!isProfileOpen)}
                        className="block"
                      >
                        <div className="flex text-text-900 hover:bg-gray-100 dark:text-text-200 dark:hover:bg-gray-700  items-center justify-start py-1 px-4  gap-2">
                          <MdDashboard className="h-5 w-5 " />
                          <span>Dashboard</span>
                        </div>
                      </Link>
                      <Link
                        to="/settings"
                        onClick={() => setIsProfileOpen(!isProfileOpen)}
                        className="block"
                      >
                        <div className="flex text-text-900 hover:bg-gray-100 dark:text-text-200 dark:hover:bg-gray-700  items-center justify-start py-1 px-4 gap-2">
                          <IoSettingsOutline className="h-5 w-5 " />
                          <span>Settings</span>
                        </div>
                      </Link>

                      <Link
                        to="/logout"
                        onClick={() => setIsProfileOpen(!isProfileOpen)}
                        className="block"
                      >
                        <div className="flex items-center justify-start rounded-b-lg py-1 px-4 pb-2 text-red-500 hover:bg-gray-100 dark:hover:bg-gray-700 gap-2">
                          <IoLogOutOutline className="h-5 w-5" />
                          <span>Logout</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!token && (
              <Link
                to="/login"
                className="hidden xl:block text-sm bg-gradient-to-r from-green-500 to-teal-500 text-white rounded-lg px-4 py-2 hover:bg-[#653dff] transition-colors duration-200"
              >
                Login / Register
              </Link>
            )}
            <Link
              to="/evaluate"
              className="hidden xl:block text-sm bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg px-4 py-2 hover:bg-[#653dff] transition-colors duration-200"
            >
              Evaluate at no-cost
            </Link>
            <div className="xl:hidden flex">
              <Sidebar />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;

const NavItem = ({
  item,
  openDropdown,
  handleMouseEnter,
  handleMouseLeave,
  handleLinkClick,
}) => {
  const isOpen = openDropdown === item.title;

  return (
    <div
      className="relative"
      onMouseEnter={() => handleMouseEnter(item.title)}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        to={item.path}
        className="inline-flex justify-center w-full px-4 py-2 text-base font-semibold text-text-900 hover:text-green-500 dark:text-text-200 dark:hover:text-text-300 focus:outline-none"
        onClick={handleLinkClick}
      >
        {item.title}
        {item.dropdownIcon && (
          <ChevronDownIcon className="h-4 w-4 ml-2 mt-1 inline-block" />
        )}
      </Link>
      {isOpen && item.submenu && (
        <DropdownMenu
          submenu={item.submenu}
          handleLinkClick={handleLinkClick}
        />
      )}
    </div>
  );
};

const DropdownMenu = ({ submenu, handleLinkClick }) => {
  return (
    <div className="absolute left-0 w-40 bg-background-100 dark:bg-background-900 border border-gray-300 dark:border-gray-600 divide-y divide-primary-200 dark:divide-primary-400 shadow-xl">
      {submenu.map((subItem) => (
        <DropdownItem
          key={subItem.title}
          item={subItem}
          handleLinkClick={handleLinkClick}
        />
      ))}
    </div>
  );
};

const DropdownItem = ({ item, handleLinkClick }) => {
  const [isNestedOpen, setIsNestedOpen] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsNestedOpen(true)}
      onMouseLeave={() => setIsNestedOpen(false)}
    >
      {item.isExternal ? (
        <AppNavigator navigateTo={item.path}>
          <span className=" w-full h-full flex items-center justify-start px-4 py-2 text-sm text-text-900 hover:bg-gray-100 dark:text-text-200 dark:hover:bg-gray-700">
            {item.title}
          </span>
        </AppNavigator>
      ) : (
        <Link
          to={item.path}
          className={`block px-4 py-2 text-sm text-text-900 hover:bg-gray-100 dark:text-text-200 dark:hover:bg-gray-700  `}
          onClick={handleLinkClick}
        >
          {item.title}
          {item.dropdownIcon && (
            <ChevronRightIcon className="h-4 w-4 ml-1 inline-block" />
          )}
        </Link>
      )}
      {isNestedOpen && item.submenu && (
        <div className="absolute left-full top-0 w-40 mt-0 bg-background-100 dark:bg-background-900 border border-gray-300 dark:border-gray-600 divide-y divide-primary-200 dark:divide-primary-400  shadow-xl">
          {item.submenu.map((nestedItem) => (
            <Link
              key={nestedItem.title}
              to={nestedItem.path}
              className="block px-4 py-2 text-xs font-semibold text-text-900 hover:bg-gray-100 dark:text-text-200 dark:hover:bg-gray-700"
              onClick={handleLinkClick}
            >
              {nestedItem.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
